import React, { useState, useEffect } from "react";
import ReactGA from "react-ga4";
import { CatholicDetails } from "./EventTabs/CatholicDetails";
import { MehndiDetails } from "./EventTabs/MehndiDetails";
import { HaldiDetails } from "./EventTabs/HaldiDetails";
import { HinduDetails } from "./EventTabs/HinduDetails";
import { ReceptionDetails } from "./EventTabs/ReceptionDetails";

const api = "https://ancient-lake-430a.kory.workers.dev";
// const api = "http://127.0.0.1:8787/rsvp/mred";

async function fetchTableData(
  key,
  setTableData,
  setError,
  setIsLoadingTableData
) {
  try {
    setIsLoadingTableData(true);
    const response = await fetch(`${api}/rsvp/${key}`);

    if (!response.ok) {
      const errBody = await response.json();
      if (!errBody || !errBody.error) {
        throw new Error(
          `error from api: ${response.status} - ${response.statusText}`
        );
      }
      if (errBody.error.includes("No rsvp for key")) {
        // Set the specific error message when "no rsvp for key" is found
        setError(
          "Make sure you enter your RSVP code exactly as it appears on your invitation."
        );
      } else {
        // For other errors, set a generic error message
        setError("An error occurred");
      }
      throw new Error(errBody.error);
    }

    const data = await response.json();
    setTableData(data.people);
    setError(null);
  } catch (error) {
    setError(error.message);
  } finally {
    setIsLoadingTableData(false);
  }
}

const EventDetailsTabs = () => {
  const [tableData, setTableData] = useState([]);
  const [isLoadingTableData, setIsLoadingTableData] = useState(false);
  const [error, setError] = useState(null);
  const [rsvpCodeInput, setRsvpCodeInput] = useState("");
  const [showForm, setShowForm] = useState(true);
  const [activeTab, setActiveTab] = useState(null); // Initialize activeTab to null

  const handleRsvpCodeChange = (e) => {
    setRsvpCodeInput(e.target.value);
  };
  // RSVP Key logic
  const handleRsvpCodeSubmit = async (e) => {
    e.preventDefault();

    const trimmedAndLowercasedCode = rsvpCodeInput.trim().toLowerCase();
    ReactGA.event({
      category: "Event Details",
      action: "Key Entered",
      label: `${trimmedAndLowercasedCode}`,
    }); // Track in GA
    if (
      trimmedAndLowercasedCode.length < 3 ||
      trimmedAndLowercasedCode.length > 12
    ) {
      setError("RSVP code must be between 3 and 12 characters.");
    } else {
      // Clear any previous errors
      setError(null);

      // Continue with API request
      fetchTableData(
        trimmedAndLowercasedCode,
        setTableData,
        setError,
        setIsLoadingTableData
      );
      setShowForm(false);
    }
  };

  // Tab logic

  const handleTabClick = (event) => {
    // Track tab click event
    ReactGA.event({
      category: event,
      action: "Tab Click",
    });

    setActiveTab(event);
  };

  useEffect(() => {
    // Set the initial activeTab to the event name of the first person in the group
    if (tableData.length > 0 && tableData[0].invites.length > 0) {
      setActiveTab(tableData[0].invites[0].event);
    }
  }, [tableData]);

  const renderTabContent = (event) => {
    switch (event) {
      case "church":
        return <CatholicDetails />;
      case "mehndi":
        return <MehndiDetails />;
      case "haldi":
        return <HaldiDetails />;
      case "hindu":
        return <HinduDetails />;
      case "reception":
        return <ReceptionDetails />;
      default:
        return null;
    }
  };

  return (
    <div id="Events">
      {showForm || error ? (
        <div>
          {/* Copy styling from */}
          <div className="max-w-[1240px] flex flex-col flex-items-center p-4 mx-auto">
            {/* Content wrapper */}
            {/* start card */}
            {/* Start RSVP code collection */}
            <div className="max-w-4xl px-4 py-5 mx-auto">
              <h2 className="text-center text-4xl md:text-5xl py-4 text-[#a09c94] uppercase">
                Unlock Your Event Details
              </h2>
              <div className="bg-white min-h-[40vh] md:min-h-[20vh] flex flex-col justify-center items-center rounded-xl shadow-md p-4 sm:p-7">
                <p className="text-center p-4 text-xl">
                  Enter your unique RSVP code to access exclusive event
                  information.
                </p>
                {/* Start error */}
                {error && (
                  <div>
                    <div
                      className="bg-red-50 border text-red-700 border-red-200 rounded-md p-4 m-1 md:m-4"
                      role="alert"
                    >
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <svg
                            className="flex-shrink-0 inline w-4 h-4 mr-3"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                          >
                            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                          </svg>
                        </div>
                        <div className="ml-4">
                          <h3 className="text-md text-red-800 font-semibold">
                            Hmm... We couldn't find the RSVP code you entered.
                          </h3>
                          <div className="mt-1 text-md text-red-700">
                            {error}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {/* end error */}
                {/* working form */}
                <form onSubmit={handleRsvpCodeSubmit}>
                  <div className="flex justify-center p-4">
                    <div className="relative">
                      <input
                        type="text"
                        id="rsvp_code"
                        className={`block px-2.5 pb-1.5 text-bold pt-3 w-64 text-2xl uppercase text-gray-900 bg-transparent rounded-lg border-1 shadow-md ${
                          error ? "border-red-500" : "border-[#a09c94]"
                        } appearance-none focus:outline-none focus:ring-0 focus:border-[#a09c94] peer`}
                        placeholder=""
                        value={rsvpCodeInput}
                        onChange={handleRsvpCodeChange}
                      />
                      <label
                        htmlFor="rsvp_code"
                        className="absolute text-md text-gray-500  duration-300 transform -translate-y-3 scale-75 top-1 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-[#a09c94] peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-3 left-1"
                      >
                        Enter your RSVP code
                      </label>
                    </div>
                  </div>
                  <div className="flex mx-auto justify-center">
                    <button
                      type="Submit"
                      className="items-center mt-6 mb-8 sm:mb-4 font-bold px-8 py-1 text-[1.4em] border border-black rounded-md hover:bg-black hover:text-white"
                    >
                      Unlock
                    </button>
                  </div>
                </form>

                {/* End form */}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          {isLoadingTableData ? (
            <div className="flex items-center justify-center h-screen bg-[#f5f5f5]">
              <div role="status">
                <svg
                  aria-hidden="true"
                  className="w-8 h-8 mr-2 text-gray-300 animate-spin fill-[#a09c94]"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          ) : (
            <div>
              {tableData.length > 0 && (
                <div>
                  {/* Tab content */}
                  <div className="flex flex-col mx-auto">
                    <h2 className="mt-8 text-center text-3xl md:text-5xl py-4 text-gray-900 uppercase">
                      Your Event Details
                    </h2>
                    {tableData[0].invites.length === 1 ? (
                      // Description if only invited to one event
                      <p className="text-center p-4 text-xl">
                        You're invited to a special event!
                      </p>
                    ) : (
                      // Description if invitied to multiple events
                      <p className="text-center p-4 text-xl">
                        Click on each tab to peek at what's happening for each
                        event.
                      </p>
                    )}
                    <div className="w-full max-w-[2560px] mx-auto py-4 text-black">
                      <div className="flex flex-row font-bold flex-wrap mx-auto justify-center uppercase text-sm md:text-base sticky top-14 p-2 2xl:p-4 z-40 bg-[#f5f5f5]/80 backdrop-opacity-90 backdrop-blur-sm">
                        {tableData[0].invites.map((invite, index) => (
                          <Tab
                            key={index}
                            event={invite.event}
                            activeTab={activeTab}
                            handleTabClick={handleTabClick}
                          />
                        ))}
                      </div>
                      <div className="mt-10">
                        {activeTab && renderTabContent(activeTab)}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const Tab = ({ event, activeTab, handleTabClick }) => {
  const isActive = event === activeTab;

  const displayName = {
    church: "Catholic Ceremony",
    mehndi: "Mehndi Night",
    haldi: "Haldi Ceremony",
    hindu: "Hindu Ceremony",
    reception: "Reception",
  };

  return (
    <div
      className={`text-gray-500 px-2 md:px-4 py-2 cursor-pointer hover:text-black font-bold ${
        isActive ? " border-[#a09c94] bg-gray-200/50 rounded-md shadow-md" : ""
      }`}
      onClick={() => handleTabClick(event)}
    >
      <span
        className={
          isActive
            ? "text-black underline underline-offset-8 font-extrabold"
            : ""
        }
      >
        {displayName[event]}
      </span>
    </div>
  );
};

export default EventDetailsTabs;
//This component takes the RSVP code for a guest group and returns the events for the first guest within the group. This assumes that the first guest in the group is invited to the most events in the AirTable.
// It doesn't check RSVP status, just events.
