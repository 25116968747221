import { Navbar } from "../components/Navbar";
import { Footer } from "../components/Footer";
import { BackToTop } from "../components/BackToTop";
import { RSVPHero } from "../components/RSVPHero";
import { RSVPSummary } from "../components/RSVPSummary";

export const YourRSVP = () => {
  return (
    <div>
      <title>Your RSVP | Edward and Mrunal</title>
      <Navbar />
      <RSVPHero />
      <RSVPSummary />
      <Footer />
      <BackToTop />
    </div>
  );
};
//Component to import YourRSVP page components.
