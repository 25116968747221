import React from "react";
import SideImage from "../../assets/img/events/mehndi-graphic.jpg";

const Steps = [
  {
    title: "Arrival and Welcome",
    description:
      "Guests are welcomed with colorful decorations and music as they arrive at the Mehndi venue to celebrate the upcoming wedding of Mrunal and Edward.",
  },
  {
    title: "Mehndi Application",
    description:
      "Henna artists apply intricate Mehndi designs to the hands and feet of Mrunal, the bride, and to the guests as well. This is a joyful and artistic process.",
  },
  {
    title: "Refreshments:",
    description:
      "Traditional Indian cuisine, snacks, and sweets are served to guests, adding to the festive ambiance of the Mehndi Night.",
  },
  {
    title: "Mehndi Ceremony",
    description:
      "The night continues with joyful celebrations of togetherness through dance, music, and food.",
  },
];

export const MehndiTimeline = () => {
  return (
    <div className="px-4 py-8 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-16">
      <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-5">
        <div className="grid gap-4 md:grid-cols-2 md:col-span-2 lg:col-span-3">
          {Steps.map((step, index) => (
            <div key={index} className="rounded lg:p-5">
              <div className="flex items-center mb-1">
                <span className="flex items-center justify-center w-6 h-6 mr-2 text-xl font-bold shadow-md text-[#f5f5f5] rounded-full bg-[#a09c94]">
                  {index + 1}
                </span>
                <p className="text-xl md:text-2xl font-bold leading-6">
                  {step.title}
                </p>
              </div>
              <p className="text-md md:text-xl text-gray-900">
                {step.description}
              </p>
            </div>
          ))}
        </div>
        <div
          className="hidden lg:block relative md:col-span-2 lg:col-span-2 w-full h-full bg-cover bg-center cursor-default rounded-xl shadow-md"
          style={{
            backgroundImage: `url(${SideImage})`,
            backgroundPosition: "10% 10%",
          }}
        ></div>
      </div>
    </div>
  ); //Component to show the order of events at the Mehndi.
};
