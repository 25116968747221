import React from "react";
import OutfitImage from "../../assets/img/outfits/hindu-outfits.png"; // Update the path to your image

export const HinduDressCode = () => {
  const dressCodeItems = [
    {
      category: "Ladies",
      description: (
        <>
          Let your elegance shine through with your wedding ensemble. Drape
          yourself in an exquisite <span className="font-bold">Sari</span>,{" "}
          <span className="font-bold">Lehenga</span>, or{" "}
          <span className="font-bold">Kurta</span> in pastel/light coloured
          hues.
        </>
      ),
    },
    {
      category: "Gentlemen",
      description: (
        <>
          Embrace the essence of a Hindu wedding with your choice of attire. Opt
          for a classic <span className="font-bold">Sherwani</span> or{" "}
          <span className="font-bold">Kurta</span> in pastel/light colors.
        </>
      ),
    },
  ];
  return (
    <div className="max-w-[1240px] flex flex-col mx-auto items-center text-center p-4 py-6">
      {/* Top description */}
      <p className="text-xl md:text-2xl">
        Join us in celebrating this sacred union by wearing pastel/light
        coloured formal Indian attire.
      </p>
      <section>
        <div className="gap-16 items-center py-8 px-4 mx-auto max-w-screen-xl sm:grid sm:grid-cols-2 lg:py-16 lg:px-6 ">
          <div className="grid grid-cols-1 gap-4 mt-4">
            <div className="flex justify-end items-end pointer-events-none">
              <img
                src={OutfitImage}
                alt="This is what you should wear."
                className="w-[400px] h-auto mx-auto mb-4 rounded-md"
              />
            </div>
          </div>
          <div className="flex-col text-center bg-white p-4 rounded-md shadow-lg sm:text-lg sm:ml-[-40%] md:ml-[-30%]">
            {dressCodeItems.map((item, index) => (
              <div key={index} className="">
                <span className="inline-block py-1 px-2 m-4 rounded-full uppercase bg-[#a09c94]/40 text-gray-700 text-md font-medium tracking-widest">
                  {item.category}
                </span>
                <p className="ml-2 text-lg xl:text-xl">{item.description}</p>
                <hr className="m-4 flex mx-auto border-b border-grey-300 w-1/2" />
              </div>
            ))}
          </div>
        </div>
      </section>
      {/* Bottom description */}
      <div className="p-4">
        <p className="text-xl md:text-2xl">
          We are so excited to celebrate our love through tradition during this
          Hindu wedding ceremony.
        </p>
      </div>
    </div>
  );
};
//Component to show the guests what to wear to the Hindu Ceremony.
