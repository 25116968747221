import React from "react";
import SideImage from "../../assets/img/events/hindu-graphic.jpg";

const Steps = [
  {
    title: "Prelude and Milni",
    description:
      "The wedding begins with the Milni, where the respective families meet, exchange greetings, and welcome each other. It is a joyous occasion symbolizing the union of two families.",
  },
  {
    title: "Ganesh Puja",
    description:
      "The ceremony starts with the worship of Lord Ganesh, the remover of obstacles, to seek his blessings and remove any hindrances from the wedding.",
  },
  {
    title: "Kanyadaan",
    description:
      "The father of Mrunal performs the Kanyadaan, giving away his daughter to Edward while seeking his promise to protect and care for her.",
  },
  {
    title: "Mangalashtak",
    description:
      "Edward and Mrunal exchange vows in front of the sacred fire. Each of the seven steps they take together symbolizes a vow for a happy and prosperous life.",
  },
  {
    title: "Saptapadi",
    description:
      "Edward and Mrunal take seven steps together, each step representing a vow for love, harmony, understanding, and lifelong togetherness.",
  },
  {
    title: "Mangalsutra and Sindoor",
    description:
      "Edward places a sacred necklace (Mangalsutra) around Mrunal's neck and applies red vermillion (Sindoor) on her forehead, signifying her married status.",
  },
  {
    title: "Aashirwad",
    description:
      "Elders and guests shower Edward and Mrunal with blessings and good wishes for a happy and prosperous life together.",
  },
  {
    title: "Post-Wedding Rituals",
    description:
      "The wedding is followed by various post-wedding rituals, including the reception, where family and friends celebrate Edward and Mrunal with music, dance, and delicious food.",
  },
];

export const HinduTimeline = () => {
  return (
    <div className="px-4 py-8 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-16">
      <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-5">
        <div className="grid gap-4 md:grid-cols-2 md:col-span-2 lg:col-span-3">
          {Steps.map((step, index) => (
            <div key={index} className="rounded lg:p-5">
              <div className="flex items-center mb-1">
                <span className="flex items-center justify-center w-6 h-6 mr-2 text-xl font-bold shadow-md text-[#f5f5f5] rounded-full bg-[#a09c94]">
                  {index + 1}
                </span>
                <p className="text-xl md:text-2xl font-bold leading-6">
                  {step.title}
                </p>
              </div>
              <p className="text-md md:text-xl text-gray-900">
                {step.description}
              </p>
            </div>
          ))}
        </div>
        <div
          className="hidden lg:block relative md:col-span-2 lg:col-span-2 w-full h-full bg-cover bg-center cursor-default rounded-xl shadow-md"
          style={{
            backgroundImage: `url(${SideImage})`,
            backgroundPosition: "90% 30%",
          }}
        ></div>
      </div>
    </div>
  );
};
//Component to show the order of events at the hindu wedding.
