import React, { lazy, Suspense } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import ReactGA from "react-ga4";
import { Loading } from "./components/Loading";
import { Rsvp } from "./pages/Rsvp";
import { YourRSVP } from "./pages/YourRSVP";
import { EventDetails } from "./pages/EventDetails";

// Initialize Google Analytics
ReactGA.initialize("G-HSE71GWTEE");

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
    },
  },
});

const Home = lazy(() =>
  import("./pages/Home").then((module) => {
    return { default: module.Home };
  })
);
const Engagement = lazy(() =>
  import("./pages/Engagement").then((module) => {
    return { default: module.Engagement };
  })
);
const FAQs = lazy(() =>
  import("./pages/FAQs").then((module) => {
    return { default: module.FAQs };
  })
);

const NotFound = lazy(() =>
  import("./pages/NotFound").then((module) => {
    return { default: module.NotFound };
  })
);
function App() {
  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <div className="App">
          <Routes>
            <Route
              index
              element={
                <Suspense fallback={<Loading />}>
                  <Home />
                </Suspense>
              }
            />
            <Route
              path="/engagement"
              element={
                <Suspense fallback={<Loading />}>
                  <Engagement />
                </Suspense>
              }
            />
            <Route
              path="/event-details"
              element={
                <Suspense fallback={<Loading />}>
                  <EventDetails />
                </Suspense>
              }
            />
            <Route
              path="/FAQs"
              element={
                <Suspense fallback={<Loading />}>
                  <FAQs />
                </Suspense>
              }
            />
            <Route
              path="/rsvp/:key?"
              element={
                <Suspense fallback={<Loading />}>
                  <Rsvp />
                </Suspense>
              }
            />
            <Route
              path="/yourRSVP/:key?"
              element={
                <Suspense fallback={<Loading />}>
                  <YourRSVP />
                </Suspense>
              }
            />
            <Route
              path="/404"
              element={
                <Suspense fallback={<Loading />}>
                  <NotFound />
                </Suspense>
              }
            />
            <Route path="*" element={<Navigate to="/404" />} />
          </Routes>
        </div>
      </QueryClientProvider>
    </BrowserRouter>
  );
}

export default App;
