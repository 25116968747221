import React from "react";
import OutfitImage from "../../assets/img/outfits/mehndi-outfits.png"; // Update the path to your image

export const MehndiDressCode = () => {
  const dressCodeItems = [
    {
      category: "Ladies",
      description: (
        <>
          Let your elegance shine through with your Mehndi ensemble. Adorn
          yourself in a colourful <span className="font-bold">Sari</span>,{" "}
          <span className="font-bold">Lehenga</span> or{" "}
          <span className="font-bold">Kurta</span>.
        </>
      ),
    },
    {
      category: "Gentlemen",
      description: (
        <>
          <span className="font-bold">Gentlemen</span>, embrace the Mehndi vibes
          with your choice of attire. Opt for a vibrant{" "}
          <span className="font-bold">Kurta</span>.
        </>
      ),
    },
  ];
  return (
    <div className="max-w-[1240px] flex flex-col mx-auto items-center text-center p-4 py-6">
      {/* Top description */}
      <p className="text-xl md:text-2xl">
        Get ready to immerse yourself in the enchanting world of Mehndi with our
        vibrant dress code! Join us in celebrating this night of beauty and
        artistry by wearing colorful outfits that radiate joy and tradition.
      </p>
      <section>
        <div className="gap-16 items-center py-8 px-4 mx-auto max-w-screen-xl sm:grid sm:grid-cols-2 lg:py-16 lg:px-6 ">
          <div className="grid grid-cols-1 gap-4 mt-4">
            <div className="flex justify-end items-end pointer-events-none">
              <img
                src={OutfitImage}
                alt="This is what you should wear."
                className="w-[400px] h-auto mx-auto mb-4 rounded-md"
              />
            </div>
          </div>
          <div className="flex-col text-center bg-white p-4 rounded-md shadow-lg sm:text-lg sm:ml-[-40%] md:ml-[-30%]">
            {dressCodeItems.map((item, index) => (
              <div key={index} className="">
                <span className="inline-block py-1 px-2 m-4 rounded-full uppercase bg-[#a09c94]/40 text-gray-700 text-md font-medium tracking-widest">
                  {item.category}
                </span>
                <p className="ml-2 text-lg xl:text-xl">{item.description}</p>
                <hr className="m-4 flex mx-auto border-b border-grey-300 w-1/2" />
              </div>
            ))}
          </div>
        </div>
      </section>
      {/* Bottom description */}
      <div className="p-4">
        <p className="text-xl md:text-2xl">
          Let's make this Mehndi night a truly unforgettable experience, filled
          with love, laughter, and culture!
        </p>
      </div>
    </div>
  );
};
//Component to show the guests what to wear to the Mehndi.
