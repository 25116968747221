import React from "react";
import { BsChevronDown } from "react-icons/bs";
// Local Imports
import HeroBgHome from "../assets/img/hero/hero-bg.jpg";
import HerobgEngagement from "../assets/img/hero/engagement-hero.jpg";
import HerobgEvents from "../assets/img/hero/event-details-hero-bw.jpg";
import HerobgFAQ from "../assets/img/hero/faqs-hero.jpg";

const HeroData = {
  Home: {
    src: HeroBgHome,
    backgroundPosition: "bg-[65%]",
    title: "Edward & Mrunal",
    subTitle: "MAY 25, 2024 - JUNE 01, 2024",
    scrollTo: "Story",
  },
  Engagement: {
    src: HerobgEngagement,
    backgroundPosition: "bg-[40%]",
    title: "Engagement",
    subTitle: "OCTOBER 08, 2022 \u00B7 ULLADULLA, NSW",
    scrollTo: "Proposal",
  },
  EventDetails: {
    src: HerobgEvents,
    backgroundPosition: "bg-[50%]",
    title: "Event Details",
    subTitle: "EVERYTHING YOU NEED TO KNOW",
    scrollTo: "Events",
  },
  FAQs: {
    src: HerobgFAQ,
    backgroundPosition: "bg-[50%]",
    title: "Frequently Asked Questions",
    subTitle: "",
    scrollTo: "Questions",
  },
};

export const Hero = ({ PageName }) => {
  const { src, backgroundPosition, title, subTitle, scrollTo } =
    HeroData[PageName] || {};

  return (
    <div className="w-full h-screen">
      <div
        rel="preload"
        as="image"
        className={`top-0 left-0 w-full h-full bg-cover ${backgroundPosition}`}
        style={{ backgroundImage: `url(${src})` }}
      ></div>

      <div className="bg-black/60 absolute top-0 left-0 w-full h-screen"></div>
      {/* Overlay */}
      <div className="absolute top-0 w-full h-full flex flex-col justify-center text-center text-white">
        {/* Text area */}
        <div className="w-full p-4">
          <h1 className="md:text-[5em] sm:text-6xl text-5xl md:py-6 drop-shadow-2xl uppercase mx-auto font-light">
            {title}
          </h1>
          {/* Page sub title */}
          <div>
            <p className="md:text-[1.7em] sm:text-3xl text-2xl">{subTitle}</p>
          </div>
          <div className="flex justify-center pt-5">
            {/* RSVP Button on Home page */}
            {PageName === "Home" ? (
              <a
                href="/rsvp"
                className="px-8 py-[6px] border font-bold border-gray-200 rounded-sm hover:bg-white hover:text-black"
              >
                RSVP
              </a>
            ) : (
              // Scroll to arrow on other pages
              <a
                href={`#${scrollTo}`}
                className="text-gray-300 hover:text-white animate-bounce"
              >
                <BsChevronDown size={24} />
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

// This component is for the hero section on all pages. The hero image is set as a variable up the top. A prop is passed through in each of the pages component and then depending on the prop it maps to an image to display. This also handles the heading and sub heading text along with the scroll to arrow.
