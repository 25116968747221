import React from "react";
import { BsChevronDown } from "react-icons/bs";
import BannerImg from "../assets/img/home/home-section.jpg";

export const RSVPHero = () => {
  return (
    <div className="relative w-full h-[75vh]">
      {/* Image */}
      <div
        className="w-full h-full bg-cover bg-center md:bg-[position:0%_22%]"
        style={{
          backgroundImage: `url(${BannerImg})`,
          minHeight: "75vh",
        }}
      ></div>
      {/* Black Overlay */}
      <div className="absolute inset-0 bg-black opacity-50"></div>
      {/* Content Wrapper */}
      <div className="absolute inset-0 flex flex-col items-center justify-center text-center p-4 text-white">
        {/* Title */}
        <h1 className="md:text-[5em] sm:text-6xl text-5xl md:py-6 drop-shadow-2xl uppercase mx-auto font-light">
          RSVP
        </h1>
        {/* Page sub title */}
        <div>
          <p className="md:text-[1.7em] sm:text-3xl text-2xl uppercase">
            RSVP by 1st MARCH 2024
          </p>
        </div>
        <div className="flex justify-center pt-5">
          {/* RSVP Button on Home page */}
          <a
            href={`#RSVPForm`}
            className="text-gray-300 hover:text-white animate-bounce"
          >
            <BsChevronDown size={24} />
          </a>
        </div>
      </div>
    </div>
  );
};

// This component is the Hero section on the RSVP page.
