import React from "react";

export const SectionTitle = (props) => {
  const { section } = props;

  return (
    <div className="w-full p-4 py-4">
      <div className="inline-flex items-center justify-center w-full">
        {/* Horizontal rule */}
        <hr className="w-[95%] sm:w-[75%] md:w-[75%] h-px my-8 border-0 bg-gray-700" />
        {/* Subtitle */}
        <span className="absolute px-3 font-medium -translate-x-1/2 left-1/2 text-white bg-gray-900  text-xl sm:text-2xl md:text-3xl uppercase">
          {section} {/* Display the prop value */}
        </span>
      </div>
    </div>
  );
};
// This component is the section break used on the event details page. The section title is passed as a prop and then displayed.
