import React from "react";
import { useNavigate } from "react-router-dom";
import Cheers from "../assets/img/rsvp/cheers.png";

export function RsvpClosed() {
  const navigate = useNavigate();
  return (
    <div className="w-full py-8 px-4" id="Story">
      <div className="max-w-[1024px] mx-auto flex">
        <section className="bg-white mx-auto rounded-xl shadow-md p-4 text-gray-900 body-font">
          <div className="container mx-auto flex px-5 py-6 md:flex-row flex-col">
            <div className="lg:flex-grow md:w-2/3 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
              <h2 className="mx-auto text-2xl sm:text-3xl md:text-4xl py-2 align-center text-[#a09c94] uppercase">
                RSVP Closed
              </h2>
              <p className="mb-4 text-center text-lg leading-relaxed">
                We'd like to extend our heartfelt thanks to everyone who has
                responded to our wedding invitation. RSVP for the wedding closed
                on March 1st, 2024.
              </p>
              <p className="mb-4 text-center text-lg leading-relaxed">
                For those who haven't yet had the chance to respond, please note
                that we've concluded our RSVP process. However, if you have any
                questions or need further assistance, please don't hesitate to
                get in touch with us directly.
              </p>

              <p className="mb-4 text-center text-lg leading-relaxed">
                We're looking forward to celebrating this special day with our
                loved ones, and we appreciate your understanding and support.
              </p>
              <p className="mx-auto mb-4 text-center text-lg leading-relaxed">
                Love Edward and Mrunal. 🤍
              </p>
              <div className="mx-auto">
                <button
                  onClick={() => navigate("/event-details")}
                  className="py-2 px-4 m-8 rounded-sm shadow-md border font-semibold uppercase text-white bg-black hover:bg-[#a09c94] w-48 relative justify-end md:justify-center"
                >
                  View Event Details
                </button>
              </div>
            </div>
            <div className="hidden md:block mx-auto my-auto lg:max-w-lg md:w-1/3 w-5/6">
              <div className="flex flex-col items-center">
                <img
                  src={Cheers}
                  alt="Cheers for RSVPing"
                  className="w-[300px] h-auto mx-auto mb-4 pointer-events-none"
                />
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
// RSVP closed content.
