import React, { useState, useEffect } from "react";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import { Link, useLocation } from "react-router-dom";

export const Navbar = () => {
  const location = useLocation();
  const [nav, setNav] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const handleNav = () => {
    setNav(!nav);
  };

  const handleLinkClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setNav(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      if (scrollTop > window.innerHeight * 0.1) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      className={`flex justify-between items-center fixed top-0 left-0 right-0 z-50 h-[60px] px-4 text-white transition-all duration-500 ${
        isScrolled ? "bg-black" : "bg-transparent"
      }`}
    >
      <ul className="uppercase hidden md:flex mx-auto">
        <Link
          to="/"
          onClick={handleLinkClick}
          className={
            location.pathname === "/" ? "underline underline-offset-8" : ""
          }
        >
          <li className="p-4 hover:underline underline-offset-8">
            We're getting married
          </li>
        </Link>
        <Link
          to="/engagement"
          onClick={handleLinkClick}
          className={
            location.pathname === "/engagement"
              ? "underline underline-offset-8"
              : ""
          }
        >
          <li className="p-4 hover:underline underline-offset-8">
            Our Engagement
          </li>
        </Link>
        <Link
          to="/event-details"
          onClick={handleLinkClick}
          className={
            location.pathname === "/event-details"
              ? "underline underline-offset-8"
              : ""
          }
        >
          <li className="p-4 hover:underline underline-offset-8">
            Event Details
          </li>
        </Link>
        <Link
          to="/faqs"
          onClick={handleLinkClick}
          className={
            location.pathname === "/faqs" ? "underline underline-offset-8" : ""
          }
        >
          <li className="p-4 hover:underline underline-offset-8">FAQs</li>
        </Link>
        <div className="md:flex flex-col mx-auto p-4 h-[56px] justify-center">
          <Link
            to="/rsvp"
            onClick={handleLinkClick}
            className="md:flex items-center px-8 py-[6px] border font-bold border-gray-200 rounded-sm hover:bg-white hover:text-black"
          >
            RSVP
          </Link>
        </div>
      </ul>

      {/* Mobile menu start*/}
      <div onClick={handleNav} className="block ml-auto md:hidden">
        {/* Nice to have: Animate the hamburger menu */}
        {nav ? <AiOutlineClose size={20} /> : <AiOutlineMenu size={20} />}
      </div>
      <div
        className={`${
          nav
            ? "fixed left-0 top-0 w-[80%] h-full border-r rounded-r-xl border-r-gray-300 backdrop-blur-sm bg-[#f5f5f5]/60 text-black ease-in-out duration-500"
            : "fixed left-[-100%]"
        }`}
      >
        <ul className="uppercase mt-4 p-4 text-[1.3em]">
          <Link
            to="/"
            onClick={handleLinkClick}
            className={
              location.pathname === "/" ? "underline underline-offset-4" : ""
            }
          >
            <li className="p-4 border-b font-bold border-neutral-700 hover:underline underline-offset-4">
              We're getting married
            </li>
          </Link>
          <Link
            to="/engagement"
            onClick={handleLinkClick}
            className={
              location.pathname === "/engagement"
                ? "underline underline-offset-4"
                : ""
            }
          >
            <li className="p-4 border-b font-bold border-neutral-700 hover:underline underline-offset-4">
              Our Engagement
            </li>
          </Link>
          <Link
            to="/event-details"
            onClick={handleLinkClick}
            className={
              location.pathname === "/event-details"
                ? "underline underline-offset-4"
                : ""
            }
          >
            <li className="p-4 border-b font-bold border-neutral-700 hover:underline underline-offset-4">
              Event Details
            </li>
          </Link>
          <Link
            to="/faqs"
            onClick={handleLinkClick}
            className={
              location.pathname === "/faqs"
                ? "underline underline-offset-4"
                : ""
            }
          >
            <li className="p-4 border-b font-bold border-neutral-700 hover:underline underline-offset-4">
              FAQs
            </li>
          </Link>
        </ul>
        <div className="flex items-center mx-auto md:hidden p-4 h-[56px] justify-center">
          <Link
            to="/rsvp"
            onClick={handleLinkClick}
            className="items-center font-bold px-8 py-1 text-[1.4em] border border-black rounded-sm hover:bg-black hover:text-white"
          >
            RSVP
          </Link>
        </div>
      </div>
      {/* Mobile menu end*/}
    </div>
  );
};

// This component is all things navbar-related, including mobile and desktop versions, links, and handling the logic to make it sticky and change color on scroll.
