import React from "react";
import { SectionTitle } from "../SectionTitle";
import { CatholicDressCode } from "./CatholicDressCode";
import { BsFillCalendarCheckFill, BsPersonHeart } from "react-icons/bs";
import CalanderInvite from "../../assets/cal/CatholicCeremony.ics";
import { CatholicTimeline } from "./CatholicTimeline";

export const CatholicDetails = () => {
  const getCalanderInvite = CalanderInvite;
  return (
    <div id="Details" className="p-4">
      <h2 className="text-center text-5xl md:text-6xl py-4 text-gray-900 uppercase">
        Catholic Ceremony
      </h2>
      <div className="max-w-[1240px] flex flex-col mx-auto items-center text-center p-4">
        <p className="text-2xl italic max-w-[800px] mt-8 mb-8 mx-auto">
          A wedding performed in a Catholic Church is a religious ceremony in
          which a couple is married in accordance with the traditions and
          sacraments of the Catholic faith.
        </p>
      </div>
      <SectionTitle section="Event Details" />
      <div className="max-w-[1240px] mx-auto grid grid-cols-1 sm:grid-cols-2 gap-8 px-4 py-16">
        {/* Date and Time content*/}
        <div className="flex flex-col items-center text-center text-4xl md:text-5xl uppercase">
          SATURDAY, MAY 25, 2024
        </div>
        <div className="flex flex-col items-center text-center text-4xl md:text-5xl uppercase">
          10:30am
        </div>
      </div>
      {/* Location and directions content*/}
      {/* Start Map */}
      <div className="relative mb-10">
        <div className="absolute inset-0 bg-gray-300">
          <iframe
            width="100%"
            height="100%"
            title="Church Location"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3303.778566043871!2d150.78596717569852!3d-34.100812673138755!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b12eff3a2b3b325%3A0x5290df2227816949!2sOur%20Lady%20Help%20of%20Christians%20Catholic%20Church%20Rosemeadow!5e0!3m2!1sen!2sau!4v1689051456460!5m2!1sen!2sau"
            className="grayscale-[100]"
          ></iframe>
        </div>
        <div className="container px-5 py-24 mx-auto flex">
          <div className="lg:w-1/3 md:w-1/2 backdrop-blur-sm bg-[#f5f5f5]/40 rounded-lg p-8 flex flex-col md:ml-auto w-full mt-10 md:mt-0 relative z-10 shadow-xl">
            <h2 className="text-3xl sm:text-4xl font-bold mb-1 uppercase">
              Location and Directions
            </h2>
            <p className="text-xl mt-2 mb-2">
              The wedding ceremony will take place at
              <br />
              Our Lady Help Of Christians Church, located at&nbsp;
              <a
                className="hover:underline underline-offset-4 font-bold hover:text-[#a09c94]"
                href="https://goo.gl/maps/i4JWucyaPdm35MNfA"
                target="_blank"
                rel="noreferrer"
              >
                80 Demetrius Road, Rosemeadow NSW 2560
              </a>
              .
            </p>
            <p className="text-xl leading-relaxed mt-2 mb-2">
              Please refer to the map for directions to the venue. There is
              ample parking available on-site, and ushers will be present to
              guide you upon arrival.
            </p>
          </div>
        </div>
      </div>
      {/* End map */}
      {/* Begin HR */}
      <SectionTitle section="What to Expect" />
      {/* End HR */}
      {/* What to expect */}
      <CatholicTimeline />
      {/* Attire */}
      <SectionTitle section="What to wear" />
      <CatholicDressCode />
      {/* Add to calander */}
      <section className="text-black body-font">
        <div className="container px-5 py-12 sm:py-18 md:py-24 mx-auto flex items-center md:flex-row flex-col">
          <div className="flex flex-col md:pr-10 md:mb-0 mb-6 pr-0 w-full md:w-auto md:text-left text-center">
            <h2 className="text-xs text-[#a09c94] uppercase tracking-widest font-medium mb-1">
              Don't forget to RSVP and Add it to Your calander
            </h2>
            <h1 className="md:text-3xl text-2xl uppercase font-medium title-font text-black">
              Be There as We Say "I Do"
            </h1>
          </div>
          <div className="flex md:ml-auto md:mr-0 mx-auto items-center shrink-0 space-x-4">
            <a href="/rsvp" target="_blank" rel="noopener noreferrer">
              <button className="bg-[#a09c94]/40 shadow-md inline-flex py-3 px-5 rounded-lg items-center hover:bg-gray-100 focus:outline-none h-[56px] ">
                <BsPersonHeart size={24} />
                <span className="ml-4 flex items-start flex-col leading-none">
                  <span className="title-font font-medium uppercase">RSVP</span>
                </span>
              </button>
            </a>

            <a href={getCalanderInvite}>
              <button className="bg-[#a09c94]/40 shadow-md inline-flex py-3 px-5 rounded-lg items-center hover:bg-gray-100 focus:outline-none h-[56px]">
                <BsFillCalendarCheckFill size={22} />
                <span className="ml-4 flex items-start flex-col leading-none">
                  <span className="title-font font-medium uppercase">
                    Add to <br />
                    Calendar
                  </span>
                </span>
              </button>
            </a>
          </div>
        </div>
      </section>
      {/* End add to calendar */}
    </div>
  );
};

// This component renders the details for the catholic wedding ceremony
