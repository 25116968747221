import React from "react";
import { AiOutlineInstagram } from "react-icons/ai";
const url = "https://www.instagram.com/__film.me/";

export const Footer = () => {
  return (
    <div className="bg-[#a09c94]/50">
      <div className="max-w-[1240px] mx-auto py-4 px-4 text-black">
        <div className="flex flex-col items-center">
          <h3 className="uppercase md:text-4xl sm:3xl text-2xl py-6">
            Edward & Mrunal
          </h3>
          <div>
            <button
              type="button"
              tareget="_blank"
              onClick={() => window.open(url, "_blank")}
              rel="nofollow"
              className="rounded-full p-2 bg-black text-white hover:bg-black/70 hover:text-white hover:shadow-sm"
            >
              <AiOutlineInstagram size={25} />
              <span className="sr-only">Intagram - Film.me</span>
            </button>
          </div>
          <div>
            <p className="uppercase text-sm py-6">
              © COPYRIGHT EDWARD & MRUNAL.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

// This component is the footer and currently the same on all pages. Nothing too crazy going on here.
