import React from "react";
import { BsFillCalendarCheckFill, BsPersonHeart } from "react-icons/bs";
import { SectionTitle } from "../SectionTitle";
import CalanderInvite from "../../assets/cal/HaldiCeremony.ics";
import { HaldiDressCode } from "./HaldiDressCode";
import { HaldiTimeline } from "./HaldiTimeline";

export const HaldiDetails = () => {
  const getCalanderInvite = CalanderInvite;
  return (
    <div id="Details" className="p-4">
      <h2 className="text-center text-5xl md:text-6xl py-4 text-gray-900 uppercase">
        Haldi Ceremony
      </h2>
      <div className="max-w-[1240px] flex flex-col mx-auto items-center text-center p-4">
        <p className="text-2xl italic max-w-[800px] mt-8 mb-8 mx-auto">
          The Haldi Ceremony, is a pre-wedding ritual in a Hindu wedding where a
          paste of turmeric, sandalwood, and other herbs is applied to the bride
          and groom's skin to bring good luck, ward off evil spirits, and give
          them a glowing complexion for their wedding day.
        </p>
      </div>
      <SectionTitle section="Event Details" />
      <div className="max-w-[1240px] mx-auto grid grid-cols-1 sm:grid-cols-2 gap-8 px-4 py-16">
        {/* Date and Time content*/}
        <div className="flex flex-col items-center text-center text-4xl md:text-5xl uppercase">
          WEDNESDAY, MAY 29, 2024
        </div>
        <div className="flex flex-col items-center text-center text-4xl md:text-5xl uppercase">
          3:00pm
        </div>
      </div>
      {/* Location and directions content*/}
      {/* Start Map */}
      <div className="relative mb-10">
        <div className="absolute inset-0 bg-gray-300">
          <iframe
            width="100%"
            height="100%"
            title="Haldi Location"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3303.6168946671955!2d150.78467620000004!3d-34.1049535!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b12eff671c9a8dd%3A0xea7fa2a9290eff25!2s28%20Greco%20Pl%2C%20Rosemeadow%20NSW%202560!5e0!3m2!1sen!2sau!4v1695605784858!5m2!1sen!2sau"
            className="grayscale-[100]"
          ></iframe>
        </div>
        <div className="container px-5 py-24 mx-auto flex">
          <div className="lg:w-1/3 md:w-1/2 backdrop-blur-sm bg-[#f5f5f5]/40 rounded-lg p-8 flex flex-col md:ml-auto w-full mt-10 md:mt-0 relative z-10 shadow-xl">
            <h2 className="text-3xl sm:text-4xl font-bold mb-1 uppercase">
              Location and Directions
            </h2>
            <p className="text-xl mt-2 mb-2">
              The Haldi Ceremony will take place at the
              <br />
              D'Mellow Residence, located at&nbsp;
              <a
                className="hover:underline underline-offset-4 font-bold hover:text-[#a09c94]"
                href="https://maps.app.goo.gl/XaaXf2QGPuYaUMUM6"
                target="_blank"
                rel="noreferrer"
              >
                28 Greco Place, Rosemeadow, NSW 2560
              </a>
              .
            </p>
            <p className="text-xl leading-relaxed mt-2 mb-2">
              Please refer to the map for directions to the venue. There is
              ample street parking available. To reach the Residence, please
              walk along the orange driveway until you reach the cream coloured
              house on the right.
            </p>
          </div>
        </div>
      </div>
      {/* End map */}
      {/* Begin HR */}
      <SectionTitle section="What to Expect" />
      <HaldiTimeline />
      {/* End HR */}

      {/* Attire */}
      <SectionTitle section="What to wear" />
      <HaldiDressCode />
      <SectionTitle section="Additonal Notes" />

      <div
        class="max-w-[1024px] flex mx-auto items-center justify-center p-4 m-2 text-xl md:text-2xl text-gray-800 rounded-lg"
        role="alert"
      >
        <svg
          class="flex-shrink-0 inline w-4 h-4 mr-10"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 20 20"
        >
          <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
        </svg>
        <span class="sr-only">Info</span>
        <div>
          <p>
            We recommend bringing a change of clothes and towel as things will
            get a bit messy during the Haldi ceremony. Don't worry; it's all
            part of the fun!
          </p>
        </div>
      </div>
      {/* Add to calander */}
      <section className="text-black body-font">
        <div className="container px-5 py-12 sm:py-18 md:py-24 mx-auto flex items-center md:flex-row flex-col">
          <div className="flex flex-col md:pr-10 md:mb-0 mb-6 pr-0 w-full md:w-auto md:text-left text-center">
            <h2 className="text-xs text-[#a09c94] uppercase tracking-widest font-medium mb-1">
              Don't forget to RSVP and Add it to Your calander
            </h2>
            <h1 className="md:text-3xl text-2xl uppercase font-medium title-font text-black">
              Be There as We Say "I Do"
            </h1>
          </div>
          <div className="flex md:ml-auto md:mr-0 mx-auto items-center shrink-0 space-x-4">
            <a href="/rsvp" target="_blank" rel="noopener noreferrer">
              <button className="bg-[#a09c94]/40 shadow-md inline-flex py-3 px-5 rounded-lg items-center hover:bg-gray-100 focus:outline-none h-[56px] ">
                <BsPersonHeart size={24} />
                <span className="ml-4 flex items-start flex-col leading-none">
                  <span className="title-font font-medium uppercase">RSVP</span>
                </span>
              </button>
            </a>
            <a href={getCalanderInvite}>
              <button className="bg-[#a09c94]/40 shadow-md inline-flex py-3 px-5 rounded-lg items-center hover:bg-gray-100 focus:outline-none h-[56px]">
                <BsFillCalendarCheckFill size={22} />
                <span className="ml-4 flex items-start flex-col leading-none">
                  <span className="title-font font-medium uppercase">
                    Add to <br />
                    Calendar
                  </span>
                </span>
              </button>
            </a>
          </div>
        </div>
      </section>
      {/* End add to calendar */}
    </div>
  );
};

// This component renders the details for the haldi component.
