import { Navbar } from "../components/Navbar";
import { Hero } from "../components/Hero";
import { Footer } from "../components/Footer";
import { BackToTop } from "../components/BackToTop";
import EventDetailsTabs from "../components/EventDetailsTabs";
import DismissibleBanner from "../components/DismissableBanner";

export const EventDetails = () => {
  return (
    <div>
      <title>Event Details | Edward and Mrunal</title>
      <Navbar />
      <Hero PageName="EventDetails" />
      <EventDetailsTabs />
      <Footer />
      <BackToTop />
      <DismissibleBanner />
    </div>
  );
};
//Component to import the Event Details page components.
