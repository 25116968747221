import React from "react";
import { BsFillCalendarCheckFill, BsPersonHeart } from "react-icons/bs";
import { SectionTitle } from "../SectionTitle";
import CalanderInvite from "../../assets/cal/HinduCeremony.ics";
import { HinduDressCode } from "./HinduDressCode";
import { HinduTimeline } from "./HinduTimeline";

export const HinduDetails = () => {
  const getCalanderInvite = CalanderInvite;
  return (
    <div id="Details" className="p-4">
      <h2 className="text-center text-5xl md:text-6xl py-4 text-gray-900 uppercase">
        Hindu Ceremony
      </h2>
      <div className="max-w-[1240px] flex flex-col mx-auto items-center text-center p-4">
        <p className="text-2xl italic max-w-[800px] mt-8 mb-8 mx-auto">
          A Hindu wedding is a traditional and elaborate religious ceremony that
          symbolises the union of two souls and their families.
        </p>
      </div>
      <SectionTitle section="Event Details" />
      <div className="max-w-[1240px] mx-auto grid grid-cols-1 sm:grid-cols-2 gap-8 px-4 py-16">
        {/* Date and Time content*/}
        <div className="flex flex-col items-center text-center text-4xl md:text-5xl uppercase">
          Friday, MAY 31, 2024
        </div>
        <div className="flex flex-col items-center text-center text-4xl md:text-5xl uppercase">
          10:00am
        </div>
      </div>
      {/* Location and directions content*/}
      {/* Start Map */}
      <div className="relative mb-10">
        <div className="absolute inset-0 bg-gray-300">
          <iframe
            width="100%"
            height="100%"
            title="Hindu Ceremony Location"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d52972.33218947375!2d151.0061651314026!3d-33.92129905349859!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b12b935eb020be5%3A0x87ae566d89897cee!2sConca%20D&#39;oro!5e0!3m2!1sen!2sau!4v1695605987321!5m2!1sen!2sau"
            className="grayscale-[100]"
          ></iframe>
        </div>
        <div className="container px-5 py-24 mx-auto flex">
          <div className="lg:w-1/3 md:w-1/2 backdrop-blur-sm bg-[#f5f5f5]/40 rounded-lg p-8 flex flex-col md:ml-auto w-full mt-10 md:mt-0 relative z-10 shadow-xl">
            <h2 className="text-3xl sm:text-4xl font-bold mb-1 uppercase">
              Location and Directions
            </h2>
            <p className="text-xl mt-2 mb-2">
              The Hindu Ceremony will take place at
              <br />
              Conca D'Oro Riverwood, located at&nbsp;
              <a
                className="hover:underline underline-offset-4 font-bold hover:text-[#a09c94]"
                href="https://maps.app.goo.gl/vY7fZWgsd5uEvjQy5"
                target="_blank"
                rel="noreferrer"
              >
                269 Belmore Road, Riverwood NSW 2210
              </a>
              .
            </p>
            <p className="text-xl leading-relaxed mt-2 mb-2">
              Please refer to the map for directions to the venue. Please note,
              the venue's on-site parking is located within the Riverwood Plaza
              carpark (12 Webb St, Riverwood NSW 2210). <br /> Assemble at the
              park on the right of the venue at 10:00am for the bharat.
            </p>
          </div>
        </div>
      </div>
      {/* End map */}
      {/* Begin HR */}
      <SectionTitle section="What to Expect" />
      {/* End HR */}
      {/* What to expect */}
      <HinduTimeline />
      {/* Attire */}
      <SectionTitle section="What to wear" />
      <HinduDressCode />
      {/* Add to calander */}
      <section className="text-black body-font">
        <div className="container px-5 py-12 sm:py-18 md:py-24 mx-auto flex items-center md:flex-row flex-col">
          <div className="flex flex-col md:pr-10 md:mb-0 mb-6 pr-0 w-full md:w-auto md:text-left text-center">
            <h2 className="text-xs text-[#a09c94] uppercase tracking-widest font-medium mb-1">
              Don't forget to RSVP and Add it to Your calander
            </h2>
            <h1 className="md:text-3xl text-2xl uppercase font-medium title-font text-black">
              Be There as We Say "I Do"
            </h1>
          </div>
          <div className="flex md:ml-auto md:mr-0 mx-auto items-center shrink-0 space-x-4">
            <a href="/rsvp" target="_blank" rel="noopener noreferrer">
              <button className="bg-[#a09c94]/40 shadow-md inline-flex py-3 px-5 rounded-lg items-center hover:bg-gray-100 focus:outline-none h-[56px] ">
                <BsPersonHeart size={24} />
                <span className="ml-4 flex items-start flex-col leading-none">
                  <span className="title-font font-medium uppercase">RSVP</span>
                </span>
              </button>
            </a>

            <a href={getCalanderInvite}>
              <button className="bg-[#a09c94]/40 shadow-md inline-flex py-3 px-5 rounded-lg items-center hover:bg-gray-100 focus:outline-none h-[56px]">
                <BsFillCalendarCheckFill size={22} />
                <span className="ml-4 flex items-start flex-col leading-none">
                  <span className="title-font font-medium uppercase">
                    Add to <br />
                    Calendar
                  </span>
                </span>
              </button>
            </a>
          </div>
        </div>
      </section>
      {/* End add to calendar */}
    </div>
  );
};

// This component renders the details for the Hindu wedding ceremony
