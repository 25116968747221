import React from "react";
import OutfitImage from "../../assets/img/outfits/church-reception-outfits.png";

export const ReceptionDressCode = () => {
  const dressCodeItems = [
    {
      category: "Ladies",
      description: (
        <>
          Select a <span className="font-bold">dress</span> that showcases your
          personal style.
        </>
      ),
    },
    {
      category: "Gentlemen",
      description: (
        <>
          We request your presence in a <span className="font-bold">suit</span>{" "}
          for our wedding reception.
        </>
      ),
    },
  ];
  return (
    <div className="max-w-[1240px] flex flex-col mx-auto items-center text-center p-4 py-6">
      {/* Top description */}
      <p className="text-xl md:text-2xl">
        Get ready to party and celebrate at our wedding reception! We invite all
        our guests to dress in Western formal attire – suits for the gents and
        fabulous dresses for the ladies. There's no specific color rule; just
        come looking sharp and ready to have a blast!
      </p>
      <section>
        <div className="gap-16 items-center py-8 px-4 mx-auto max-w-screen-xl sm:grid sm:grid-cols-2 lg:py-16 lg:px-6 ">
          <div className="grid grid-cols-1 gap-4 mt-4">
            <div className="flex justify-end items-end pointer-events-none">
              <img
                src={OutfitImage}
                alt="This is what you should wear."
                className="w-[400px] h-auto mx-auto mb-4 rounded-md"
              />
            </div>
          </div>
          <div className="flex-col text-center bg-white p-4 rounded-md shadow-lg sm:text-lg sm:ml-[-40%] md:ml-[-30%]">
            {dressCodeItems.map((item, index) => (
              <div key={index} className="">
                <span className="inline-block py-1 px-2 m-4 rounded-full uppercase bg-[#a09c94]/40 text-gray-700 text-md font-medium tracking-widest">
                  {item.category}
                </span>
                <p className="ml-2 text-lg xl:text-xl">{item.description}</p>
                <hr className="m-4 flex mx-auto border-b border-grey-300 w-1/2" />
              </div>
            ))}
          </div>
        </div>
      </section>
      {/* Bottom description */}
      <div className="p-4">
        <p className="text-xl md:text-2xl">
          Gear up as we prepare for an unforgettable night of partying, dancing,
          and toasting to our newlywed journey!
        </p>
      </div>
    </div>
  );
};
//Component to show the guests what to wear to the Reception
