import React from "react";
import OutfitImage from "../../assets/img/outfits/church-reception-outfits.png";

export const CatholicDressCode = () => {
  const dressCodeItems = [
    {
      category: "Ladies",
      description: (
        <>
          We appreciate your choice of{" "}
          <span className="font-bold">formal westerns dresses</span> for this
          Catholic wedding ceremony. Please opt for elegant and modest{" "}
          <span className="font-bold">dresses</span> that are in keeping with
          the respectful and formal atmosphere of the ceremony.
        </>
      ),
    },
    {
      category: "Gentlemen",
      description: (
        <>
          We kindly request that you wear{" "}
          <span className="font-bold">formal Western attire</span> for this
          special day. Opt for a well-fitted{" "}
          <span className="font-bold">suit</span>, dress shirt, and tie. A
          classic suit will make you look dapper and in tune with the formal
          setting.
        </>
      ),
    },
  ];
  return (
    <div className="max-w-[1240px] flex flex-col mx-auto items-center text-center p-4 py-6">
      {/* Top description */}
      <p className="text-xl md:text-2xl">
        Join us in the solemnity and elegance of a Catholic wedding ceremony in
        a Church. We request all guests to wear formal Western attire that
        reflects the reverence of this sacred occasion. Please choose modest
        dresses and suits to honor the sanctity of the event.
      </p>
      <section>
        <div className="gap-16 items-center py-8 px-4 mx-auto max-w-screen-xl sm:grid sm:grid-cols-2 lg:py-16 lg:px-6 ">
          <div className="grid grid-cols-1 gap-4 mt-4">
            <div className="flex justify-end items-end pointer-events-none">
              <img
                src={OutfitImage}
                alt="This is what you should wear."
                className="w-[400px] h-auto mx-auto mb-4 rounded-md"
              />
            </div>
          </div>
          <div className="flex-col text-center bg-white p-4 rounded-md shadow-lg sm:text-lg sm:ml-[-40%] md:ml-[-30%]">
            {dressCodeItems.map((item, index) => (
              <div key={index} className="">
                <span className="inline-block py-1 px-2 m-4 rounded-full uppercase bg-[#a09c94]/40 text-gray-700 text-md font-medium tracking-widest">
                  {item.category}
                </span>
                <p className="ml-2 text-lg xl:text-xl">{item.description}</p>
                <hr className="m-4 flex mx-auto border-b border-grey-300 w-1/2" />
              </div>
            ))}
          </div>
        </div>
      </section>
      {/* Bottom description */}
      <div className="p-4">
        <p className="text-xl md:text-2xl">
          There is no specific color preference, but we kindly request that all
          guests choose modest and formal Western clothing to honor the
          solemnity of the occasion.
        </p>
      </div>
    </div>
  );
};
//Component to show the guests what to wear to the Catholic wedding.