import React from "react";
import SideImage from "../../assets/img/events/reception-graphic.jpg";

const Steps = [
  {
    title: "Arrival & Welcome",
    description:
      "Get ready for a warm welcome with drinks and canapés in the courtyard.",
  },
  {
    title: "VIP Entrances",
    description:
      "Witness the grand entrances of the bridal party, family, and the couple.",
  },
  {
    title: "Cake Cutting",
    description: "Savor the sweet moment as Mrunal and Edward cut the cake.",
  },
  {
    title: "Dinner, Dances & Speeches",
    description:
      "Enjoy dinner, dance, and heartfelt speeches throughout the evening.",
  },
  {
    title: "Bride's Speech",
    description: "Mrunal takes the mic for her heartfelt words and surprises.",
  },
  {
    title: "Groom's Speech",
    description:
      "Edward shares love, laughter, and touching moments in his speech.",
  },
  {
    title: "Dance Floor Opens",
    description:
      "The dance floor is yours to show off your moves and have a blast!",
  },
];

export const ReceptionTimeline = () => {
  return (
    <div className="px-4 py-8 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-16">
      <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-5">
        <div className="grid gap-4 md:grid-cols-2 md:col-span-2 lg:col-span-3">
          {Steps.map((step, index) => (
            <div key={index} className="rounded lg:p-5">
              <div className="flex items-center mb-1">
                <span className="flex items-center justify-center w-6 h-6 mr-2 text-xl font-bold shadow-md text-[#f5f5f5] rounded-full bg-[#a09c94]">
                  {index + 1}
                </span>
                <p className="text-xl md:text-2xl font-bold leading-6">
                  {step.title}
                </p>
              </div>
              <p className="text-md md:text-xl text-gray-900">
                {step.description}
              </p>
            </div>
          ))}
        </div>
        <div
          className="hidden lg:block relative md:col-span-2 lg:col-span-2 w-full h-full bg-cover bg-center cursor-default rounded-xl shadow-md"
          style={{
            backgroundImage: `url(${SideImage})`,
            backgroundPosition: "10% 60%",
          }}
        ></div>
      </div>
    </div>
  );
};
// Component to highlight the order of events at the Reception.
