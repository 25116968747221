import React, { useState, useEffect } from "react";
import { FiArrowUp } from "react-icons/fi";

export const BackToTop = () => {
  const [showButton, setShowButton] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleScroll = () => {
    if (window.scrollY > 200) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    showButton && (
      <button
        className="fixed bottom-4 right-4 bg-[#a09c94] text-white px-2.5 py-2.5 rounded-full shadow-lg shadow-black/30 hover:bg-black/70 hover:text-white flex items-center"
        onClick={scrollToTop}
      >
        <FiArrowUp size={20} />
      </button>
    )
  );
};
// This component is the back to top button that floats in the bottom right on all pages and all devices.
