import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { RxCross2 } from "react-icons/rx";
import { BsArrowLeft } from "react-icons/bs";
import { TbClockQuestion } from "react-icons/tb";
import { FaPencilAlt } from "react-icons/fa";

const api = "https://ancient-lake-430a.kory.workers.dev";
// const api = "http://127.0.0.1:8787/rsvp/mred";

export function RSVPSummary() {
  // Get the rsvpKey from the URL using useParams
  const { key } = useParams();

  // State to hold the table data
  const [tableData, setTableData] = useState([]);
  // State to track loading status
  const [isLoadingTableData, setIsLoadingTableData] = useState(false);
  // State to store error messages
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  // Function to fetch table data from the API

  // Fetch data when the component mounts or when rsvpKey changes

  useEffect(() => {
    const fetchTableData = async () => {
      try {
        setIsLoadingTableData(true);
        const res = await fetch(`${api}/rsvp/${key}`);

        if (!res.ok) {
          // Handle errors here
          throw new Error(`error from api: ${res.status} - ${res.statusText}`);
        }

        const data = await res.json();
        setTableData(data.people); // Assuming you want to display people data
        setError(null); // Clear any previous errors on success
      } catch (error) {
        // Handle errors and set error state
        setError(error.message);
      } finally {
        setIsLoadingTableData(false);
      }
    };

    fetchTableData(); // Call the function immediately

    // Add fetchTableData to the dependency array
  }, [key]);

  const handleEditClick = () => {
    // Navigate to the edit page using the rsvpKey
    navigate(`/rsvp/${key}`);
  };
  return (
    <div>
      <div id="RSVPSummary" className="w-full py-16 px-4">
        <div className="max-w-[1042px] mx-auto">
          <h2 className="text-center text-4xl md:text-5xl py-4 mb-2 text-[#a09c94] uppercase">
            Thank you for your RSVP!
          </h2>
          {/* start card */}
          <div className="bg-white min-h-[40vh] flex justify-center items-center rounded-xl shadow-md p-4 sm:p-7">
            {/* card */}
            {error ? ( // Check for error state
              <div>
                <div
                  className="bg-red-50 border text-red-700 border-red-200 rounded-md p-4 m-4"
                  role="alert"
                >
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <svg
                        className="flex-shrink-0 inline w-4 h-4 mr-3"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                      </svg>
                    </div>
                    <div className="ml-4">
                      <h3 className="text-xl text-red-800 font-semibold">
                        Unfortunately, we are unable to retrieve your RSVP
                        summary at this time.
                      </h3>
                      <div className="mt-1 text-md text-red-700">
                        Please try again later.
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  className="flex mt-10 items-center text-xl text-[#a09c94] hover:text-bg-[#a09c94] hover:underline underline-offset-4"
                  onClick={() => navigate("/")}
                >
                  <BsArrowLeft className="mr-2" /> Back to Home
                </button>
              </div>
            ) : isLoadingTableData ? ( // Display loading message when data is being fetched
              <div className="flex items-center justify-center">
                <div role="status">
                  <svg
                    aria-hidden="true"
                    className="w-8 h-8 mr-2 text-gray-300 animate-spin fill-[#a09c94]"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              <div>
                {/* // Render the table when data is available */}
                <div className="text-xl text-center p-2 mb-8">
                  Please see below a summary of your completed RSVP
                </div>
                <div>
                  {tableData.map((person, idx) => (
                    <div
                      key={person.id}
                      className="grid md:grid-cols-2 gap-4 bg-gray-100 rounded-md shadow-sm p-4 mb-4"
                    >
                      <div className="flex flex-col justify-center">
                        <h2 className="py-2 px-4 text-center text-2xl md:text-3xl uppercase">
                          {person.firstName} {person.lastName}
                        </h2>
                      </div>
                      <div className="grid grid-cols-1 gap-4">
                        <h2 className="hidden md:block text-center text-xl uppercase">
                          Event & Response
                        </h2>
                        {person.invites.map((invite, iIdx) => (
                          <div
                            key={`people.${idx}.invites.${iIdx}`}
                            className="grid grid-cols-2 gap-4 items-center text-md md:text-xl justify-center border-b"
                          >
                            {/* Display a custom label based on the event name */}
                            <div className="text-center text-xl leading-6 text-gray-900">
                              {invite.event === "church" && "Catholic Ceremony"}
                              {invite.event === "mehndi" && "Mehndi Night"}
                              {invite.event === "haldi" && "Haldi Ceremony"}
                              {invite.event === "hindu" && "Hindu Ceremony"}
                              {invite.event === "reception" && "Reception"}
                            </div>
                            {invite.attending === true ? (
                              <span className="inline-flex items-center text-green-600 font-bold">
                                <span className="mr-1">
                                  <IoMdCheckmarkCircleOutline size={20} />
                                </span>
                                Attending
                              </span>
                            ) : invite.attending === false ? (
                              <span className="inline-flex items-center text-red-600 font-bold">
                                <span className="mr-1">
                                  <RxCross2 size={20} />
                                </span>
                                Declined
                              </span>
                            ) : (
                              <span className="inline-flex items-center text-orange-300 font-bold">
                                <span className="mr-1">
                                  <TbClockQuestion size={20} />
                                </span>
                                <span className="mr-1">Awaiting</span>
                              </span>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
                <div className="flex justify-end">
                  <button
                    onClick={handleEditClick}
                    className="flex items-center text-xl text-[#a09c94] hover:text-bg-[#a09c94] hover:underline underline-offset-4 mr-4 justify-end md:justify-center leading-6"
                  >
                    <FaPencilAlt size={15} className="mr-2" /> Edit RSVP
                  </button>

                </div>
                <div className="flex mt-6 md:mt-10 justify-center md:justify-between">
                  <button
                    onClick={() => navigate("/")}
                    className="flex items-center text-xl text-[#a09c94] hover:text-bg-[#a09c94] hover:underline underline-offset-4 justify-start md:justify-center leading-6"
                  >
                    <BsArrowLeft size={15} className="mr-1" /> Back to Home
                  </button>
                  <button
                    onClick={() => navigate("/event-details")}
                    className="py-2 px-4 m-8 mr-3 rounded-sm shadow-md border font-semibold uppercase text-white bg-black hover:bg-[#a09c94] w-48 relative justify-end md:justify-center"
                  >
                    View Event Details
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
  //This component is the RSVP summary to show the guest that their RSVP has been recieved.
}
