import React from "react";
import SideImage from "../../assets/img/events/haldi-graphic.jpg";

const Steps = [
  {
    title: "Arrival of Guests:",
    description:
      "The ceremony begins with the arrival of family and friends who come to bless Mrunal and Edward, the bride and groom.",
  },
  {
    title: "Application of Turmeric Paste:",
    description:
      "A mixture of turmeric, sandalwood, and rosewater is applied to the skin of Mrunal and Edward by family members and friends as a pre-wedding ritual.",
  },
  {
    title: "Blessings and Well-Wishes:",
    description:
      "Family members and guests take turns applying the turmeric paste and offering their blessings and good wishes to Mrunal and Edward.",
  },
  {
    title: "Playful Moments:",
    description:
      "The Haldi ceremony is known for its playful aspects. Family and friends may playfully tease Mrunal and Edward while applying the turmeric paste, adding a fun and light-hearted touch to the event.",
  },
  {
    title: "Refreshments:",
    description:
      "Traditional Indian cuisine, snacks, and sweets are served to guests, adding to the festive ambiance of the Haldi ceremony.",
  },
  {
    title: "Conclusion:",
    description:
      "The Haldi ceremony concludes with the washing off of the turmeric paste, symbolizing the purification and preparation of Mrunal and Edward for their upcoming wedding.",
  },
];

export const HaldiTimeline = () => {
  return (
    <div className="px-4 py-8 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-16">
      <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-5">
        <div className="grid gap-4 md:grid-cols-2 md:col-span-2 lg:col-span-3">
          {Steps.map((step, index) => (
            <div key={index} className="rounded lg:p-5">
              <div className="flex items-center mb-1">
                <span className="flex items-center justify-center w-6 h-6 mr-2 text-xl font-bold shadow-md text-[#f5f5f5] rounded-full bg-[#a09c94]">
                  {index + 1}
                </span>
                <p className="text-xl md:text-2xl font-bold leading-6">
                  {step.title}
                </p>
              </div>
              <p className="text-md md:text-xl text-gray-900">
                {step.description}
              </p>
            </div>
          ))}
        </div>
        <div
          className="hidden lg:block relative md:col-span-2 lg:col-span-2 w-full h-full bg-cover bg-center cursor-default rounded-xl shadow-md"
          style={{
            backgroundImage: `url(${SideImage})`,
            backgroundPosition: "10%",
          }}
        ></div>
      </div>
    </div>
  );
};
//Component to show the order of events at the haldi.
