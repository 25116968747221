import { Navbar } from "../components/Navbar";
import { Footer } from "../components/Footer";
import { BackToTop } from "../components/BackToTop";
import { RSVPHero } from "../components/RSVPHero";
// import { RsvpComponent } from "../components/RsvpComponent";
import { RsvpClosed } from "../components/RsvpClosed";

export const Rsvp = () => {
  return (
    <div>
      <title>RSVP | Edward and Mrunal</title>
      <Navbar />
      <RSVPHero />
      {/* <RsvpComponent /> */}
      <RsvpClosed />
      <Footer />
      <BackToTop />
    </div>
  );
};
//Component to import RSVP page components.
