import React, { useState } from "react";
import { MdClose } from "react-icons/md";

const DismissableBanner = () => {
  const [isVisible, setIsVisible] = useState(true);

  const dismissBanner = () => {
    setIsVisible(false);
  };

  return (
    <>
      {/* Show only on small devices (sm) */}
      <div className="md:hidden">
        {isVisible && (
          <div className="fixed z-50 mb-8 bottom-0 left-0 right-0 bg-black/80 text-white p-2 rounded-2xl flex items-center justify-between gap-4 shadow-md w-3/4 mx-auto">
            <p className="text-sm flex mx-auto text-center">
              Enhance your experience – view the event details on your
              laptop.
            </p>
            <button
              type="button"
              className="-m-3 p-3 focus-visible:outline-offset-[-4px] hover:text-gray-300"
              onClick={dismissBanner}
            >
              <span className="sr-only">Dismiss</span>
              <MdClose size={20} aria-hidden="true" />
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default DismissableBanner;
