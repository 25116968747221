import React from "react";
import SideImage from "../../assets/img/events/church-graphic.jpg";

const Steps = [
  {
    title: "Arrival and Seating:",
    description:
      "Guests are guided to their seats in the church, anticipating the union of Mrunal and Edward in holy matrimony.",
  },
  {
    title: "Processional:",
    description:
      "The bridal party enters, and Mrunal, escorted by her loved ones, walks down the aisle toward Edward, her groom.",
  },
  {
    title: "Sacramental Rites:",
    description:
      "The priest leads Mrunal and Edward through the sacramental rites of marriage, uniting them in a sacred bond.",
  },
  {
    title: "Readings:",
    description:
      "Selected readings from the Bible are delivered by chosen readers, often chosen in consultation with Mrunal and Edward.",
  },
  {
    title: "Homily:",
    description:
      "The priest provides guidance, inspiration, and reflection on the commitment of marriage, considering Mrunal and Edward's love and union.",
  },
  {
    title: "Exchange of Vows and Rings:",
    description:
      "Mrunal and Edward exchange vows and rings as a sign of their commitment and love for each other, witnessed by their friends and family.",
  },
  {
    title: "Prayers and Blessings:",
    description:
      "The ceremony includes moments of prayer and blessings, invoking God's grace and love upon Mrunal and Edward.",
  },
  {
    title: "Unity Ceremony:",
    description:
      "Any additional rituals, symbolizing the unity of Mrunal and Edward, may be included as part of the ceremony.",
  },
  {
    title: "Final Blessing:",
    description:
      "The priest offers a final blessing, extending God's grace and love to Mrunal and Edward as they embark on their journey together as a married couple.",
  },
  {
    title: "Recessional:",
    description:
      "The ceremony concludes with the recessional as Mrunal and Edward, now married, exit the church together, accompanied by the joyous applause and well-wishes of their loved ones.",
  },
];

export const CatholicTimeline = () => {
  return (
    <div className="px-4 py-8 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-16">
      <div className="grid gap-4 md:grid-cols-3">
        <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3 md:col-span-3">
          {Steps.map((step, index) => (
            <div key={index} className="rounded lg:p-5">
              <div className="flex items-center mb-1">
                <span className="flex items-center justify-center w-6 h-6 mr-2 text-xl font-bold shadow-md text-[#f5f5f5] rounded-full bg-[#a09c94]">
                  {index + 1}
                </span>
                <p className="text-xl md:text-2xl font-bold leading-6">
                  {step.title}
                </p>
              </div>
              <p className="text-md md:text-xl text-gray-900">
                {step.description}
              </p>
            </div>
          ))}
        </div>
      </div>
      {/* Display the image under the content for large screens */}
      <div
        className="hidden lg:block w-full h-96 bg-cover cursor-default rounded-xl shadow-md"
        style={{
          backgroundImage: `url(${SideImage})`,
          backgroundPosition: "10% 80%",
        }}
      ></div>
    </div>
  );
};
//Component to show the order of the events at the catholic wedding.
